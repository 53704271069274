import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const Page404 = () => {
  return (
    <Box
      display={'flex'}
      flexGrow={1}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ height: '500px' }}
    >
      <Error sx={{ fontSize: '64px', color: '#afafaf' }} />
      <Typography variant="h3" color={'#afafaf'}>
        PAGE NOT FOUND (404)
      </Typography>
    </Box>
  );
};

export default Page404;
